import React from "react"

const TestimonialCard = ({ data }) => {
  const { quote, name, service } = data

  return (
    <div className="max-w-xs pb-12 lg:px-6 relative">
      <div className="absolute top-0 -left-3 sm:left-0 z-0 opacity-50 font-crimson normal-case text-[100px]	tracking-normal font-bold text-center text-lighterPrimary">
        “
      </div>
      <p className="italic text-md mb-2 lg:mb-6 z-10 relative">{quote}</p>
      <div className="flex items-center">
        <div className="basis-3/4">
          <p className="font-bold text-sm">{name}</p>
          <p className="text-sm">{service}</p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard
