import React from "react"

const Button = ({
  onClick,
  disabled = false,
  buttonType = "default",
  children,
  style,
}) => {
  if (buttonType === "outline") {
    return (
      <button
        disabled={disabled}
        className={` ${
          disabled ? "bg-lighterPrimary" : "border border-primary "
        } ${
          disabled ? "hover:bg-lighterPrimary" : "hover:bg-lightPrimary"
        } rounded-md py-2 font-normal px-6 text-lightBlack `}
        onClick={onClick}
        style={{ ...style }}
      >
        {children}
      </button>
    )
  }

  return (
    <button
      disabled={disabled}
      className={` ${disabled ? "bg-lighterPrimary" : "bg-primary"} ${
        disabled ? "hover:bg-lighterPrimary" : "hover:bg-lightPrimary"
      } rounded-md py-2 font-semibold px-6 text-white `}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
    </button>
  )
}

export default Button
