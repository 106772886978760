import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Script, graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../components/container"
import SmallYellowBlob from "../components/small-yellow-blob"
import { CheckMark } from "../components/icons"
import Button from "../components/button"
import SquareYellowBlob from "../components/square-yellow-blob"
import SquareYellowBlobTwo from "../components/square-yellow-blob-2"
import DarkBlob from "../components/dark-blob"
import ServicesBlob from "../components/services-blob"
import ClassCardV2 from "../components/class-card-2"
import MasterClassEmailSignup from "../components/master-class-email-signup"
import TestimonialCard from "../components/testimonial-card"

const TESTIMONIALS = [
  {
    quote:
      "I've taken [my dog] to an [in person] reactive dog class, but he was deemed too reactive for it, so this online format was nice. I also liked that it was several weeks long and that it provided access to a trainer to ask questions.",
    name: "Haley B",
    service: "Reactive to Relaxed Online Class",
  },
  {
    quote:
      "My favorite part was actually having classes without the dogs present so that I could focus more on the content, and I liked being able to share experiences with the others in the class.",
    name: "Erin S",
    service: "Reactive to Relaxed Online Class",
  },
  {
    quote:
      "This training was life changing for me as the human and Duke as the dog. We understand each other better and I feel more confident not only training him, but advocating for his needs in the world.",
    name: "Michelle T",
    service: "Reactive to Relaxed Online Class",
  },
]

const TESTIMONIALS_GENERAL = [
  {
    quote:
      "[A big takeaway from the class] is that I am not the only person who struggles with a reactive dog. And that reactive dogs aren't bad or broken dogs, they may just need more understanding and time to work through some issues.",
    name: "Erin S",
    service: "Reactive to Relaxed Online Class",
  },
  {
    quote:
      "Taylor is a fun teacher and she's very helpful in addressing your dog's individual needs",
    name: "Haley B",
    service: "Reactive to Relaxed Online Class",
  },
  {
    quote:
      "[After taking this class], I feel like regardless of where [my dog] is at, I know at least one thing I can do for him or to the environment to help the situation",
    name: "Michelle T",
    service: "Reactive to Relaxed Online Class",
  },
]

const Classes = ({ data }) => {
  const {
    prismicClass: { data: pData },
  } = data
  const {
    title,
    description,
    featured_image,
    enroll_link,
    imagine_benefits,
    imagine_title,
    imagine_sub_title,
    imagine_final_title,
    you_will_learn,
    dog_will_learn,
    how_it_works,
    includes_list,
    class_dates,
    duration,
    price,
    faq,
  } = pData

  const renderHowItWorks = (titleAndDescription, emoji) => {
    return (
      <div className="flex flex-col md:flex-row items-center md:items-start mb-12">
        <div className="bg-lighterPrimary rounded-full w-[62px] h-[62px] flex items-center justify-center mr-4 ">
          <p className="font-crimson font-semibold text-3xl">{emoji}</p>
        </div>
        <div className="flex-1 text-center md:text-left">
          <PrismicRichText
            field={titleAndDescription}
            components={{
              heading3: ({ children }) => (
                <h3 className="text-lightBlack mb-1 font-crimson  normal-case text-2xl tracking-normal		">
                  {children}
                </h3>
              ),
              paragraph: ({ children }) => (
                <p className="text-gray-600 max-w-xl font-normal">{children}</p>
              ),
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Container>
        <div
          className="text-center pt-24 pb-12 max-w-xl mx-auto"
          style={{ zIndex: 30 }}
        >
          <h3 className="">Class</h3>
          <hr className="border border-primary mx-4" />
          <h1 className="mb-0 mt-2 normal-case text-5xl tracking-normal font-normal text-lightBlack">
            {title.text}
          </h1>
          <p className=" mx-auto pt-4">{description.text}</p>
          <div className="relative max-w-lg pt-6 overflow-hidden sm:overflow-visible">
            <div
              className="absolute top-[20%] left-[40%] lg:left-[40%] w-[80%] lg:w-[80%] h-[90%] lg:h-[100%]"
              style={{ zIndex: 0 }}
            >
              <SmallYellowBlob />
            </div>
            <div
              className="my-10 px-4 rounded-lg -rotate-3"
              style={{ zIndex: 11 }}
            >
              <GatsbyImage
                image={featured_image.gatsbyImageData}
                width={"100%"}
                className={"rounded-lg"}
                alt={featured_image.alt}
              />
            </div>
          </div>
        </div>
      </Container>
      <Container style={{ zIndex: "100" }}>
        <div className="text-center z-10 relative mx-auto px-4 mb-12 sm:mb-24">
          <h2 className="mb-12 font-semibold">{imagine_title.text}</h2>
          <p className="text-xl pb-2 font-semibold text-lightBlack">
            {imagine_sub_title.text}
          </p>
          <ul className="text-xl mb-12">
            {imagine_benefits.richText.map(benefit => (
              <li
                className="font-crimson font-normal text-2xl flex items-center justify-center py-1"
                key={benefit.text}
              >
                <div className="w-8">
                  <CheckMark width={26} height={26} />
                </div>
                <span className="">{benefit.text}</span>
              </li>
            ))}
          </ul>
          <h2 className="mt-16 text-[28px] font-medium max-w-2xl mx-auto sm:mb-16 mb-8">
            {imagine_final_title.text}
          </h2>
          <div className="mx-auto self-center w-fit">
            <a href={enroll_link.url}>
              <Button>Enroll Now</Button>
            </a>
            {/* <PrismicLink field={enroll_link} >
              <Button disabled>Enroll Now</Button>
            </PrismicLink> */}
          </div>
        </div>
      </Container>
      <Container>
        <div
          className={
            "flex flex-col md:flex-row items-center max-w-4xl mx-auto justify-around px-4 py-12 overflow-hidden"
          }
        >
          <div className="max-w-sm p-6 mb-16 sm:mb-0  relative overflow-visible">
            <div
              className="absolute -top-[0%] -left-[10%]  w-[110%]  h-[110%]"
              style={{ zIndex: 0 }}
            >
              <SquareYellowBlob />
            </div>
            <div className="relative" style={{ zIndex: 10 }}>
              <h3 className="font-crimson text-black capitalize text-center text-2xl">
                You will learn
              </h3>
              <ul className="">
                {you_will_learn.richText.map(benefit => (
                  <li
                    className="flex items-center justify-start py-1"
                    key={benefit.text}
                  >
                    <div className="w-4">
                      <CheckMark />
                    </div>
                    <span className="ml-2">{benefit.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="max-w-sm p-6 relative overflow-visible ">
            <div
              className="absolute -top-[10%] -left-[10%]  w-[120%]  h-[120%]"
              style={{ zIndex: 0 }}
            >
              <SquareYellowBlobTwo />
            </div>
            <div className="relative" style={{ zIndex: 10 }}>
              <h3 className="font-crimson text-black capitalize text-center text-2xl">
                Your dog will learn
              </h3>
              <ul className="">
                {dog_will_learn.richText.map(benefit => (
                  <li
                    className="flex items-center justify-start py-1"
                    key={benefit.text}
                  >
                    <div className="w-4">
                      <CheckMark />
                    </div>
                    <span className="ml-2">{benefit.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* Dark Video Section */}
      <Container noMax>
        <div
          className="absolute -top-[20%] lg:-top-[20%] w-full h-[130%] lg:h-[130%]"
          style={{ zIndex: 11 }}
        >
          <DarkBlob />
        </div>
        <div
          style={{ zIndex: 20 }}
          className="relative mt-44 max-w-xl sm:max-w-3xl  px-4 sm:px-0  mx-auto"
        >
          <h2 className="text-primary font-semibold mb-2">
            Why Online Classes?
          </h2>
          <p className=" text-lessLightGrey font-crimson text-2xl leading-6 sm:leading-8 sm:text-3xl font-light mb-8">
            Online dog training classes are becoming increasingly popular—and
            it&apos;s easy to see why.
          </p>
        </div>

        <div
          className="flex flex-col xl:flex-row justify-between items-center lg:items-start relative lg:max-w-3xl xl:max-w-6xl mt-16 mx-auto"
          style={{ zIndex: 20 }}
        >
          <div className="flex-1 lg:basis-1/3 text-lessLightGrey px-4 lg:px-0 ">
            <h3 className="mb-0 font-crimson text-white normal-case text-2xl tracking-normal	font-normal	">
              Convenience and Flexibility
            </h3>
            <p className="mb-8 max-w-sm lg:max-w-3xl text-lessLightGrey font-normal">
              You can participate in a class from the comfort of your own home.
              You don&apos;t need to rush out the door after work or drag the
              kids along. In fact, you can cozy up in your sweats, turn on the
              Instant Pot, and train your dog while dinner&apos;s cooking.
            </p>
            <h3 className="font-normal mb-0 font-crimson text-white normal-case text-2xl tracking-normal	">
              A Good Learning Environment
            </h3>
            <p className="font-normal mb-8  max-w-sm  lg:max-w-3xl text-lessLightGrey">
              Let&apos;s be honest, trying to listen to instructions while
              handling your squirmy dog is difficult. Training online allows for
              deeper discussions and focused conversations, so you can actually
              learn.
            </p>
            <h3 className="font-normal mb-0 font-crimson text-white normal-case text-2xl	tracking-normal">
              Works for All Dogs
            </h3>
            <p className="font-normal mb-4 max-w-sm lg:max-w-3xl text-lessLightGrey">
              Online training is a great option for dogs that are reactive,
              anxious, or uncomfortable in new settings. But even the calmest of
              dogs benefit from learning new skills in familiar,
              distraction-free environments.
            </p>
          </div>
          {/* <div className="flex-1 px-4 xl:px-0"> */}
          {/* <StaticImage
              src="../images/IMG_0619.jpg"
              className="mt-8 rounded-lg"
              alt="Taylor helps a client train their dog in Boise"
            /> */}
          <div className="flex-1 lg:basis-2/3 w-full max-w-2xl lg:max-w-5xl pt-4 xl:pt-0 px-4 xl:pl-16">
            {/* <StaticImage
              src="../images/IMG_0619.jpg"
              className="mt-8 rounded-lg"
              alt="Taylor helps a client train their dog in Boise"
            /> */}
            <Script
              src="https://fast.wistia.com/embed/medias/xd9ud66kwm.jsonp"
              async
            ></Script>
            <Script
              src="https://fast.wistia.com/assets/external/E-v1.js"
              async
            ></Script>
            <div
              className="wistia_responsive_padding"
              style={{ padding: "56.25% 0 0 0", position: "relative" }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{
                  height: "100%",
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              >
                <div
                  className="wistia_embed wistia_async_xd9ud66kwm videoFoam=true"
                  style={{
                    height: "100%",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    className="wistia_swatch"
                    style={{
                      height: "100%",
                      left: 0,
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      top: 0,
                      transition: "opacity 200ms",
                      width: "100%",
                    }}
                  >
                    <img
                      src="https://fast.wistia.com/embed/medias/xd9ud66kwm/swatch"
                      style={{
                        filter: "blur(5px)",
                        height: "100%",
                        objectFit: "contain",
                        width: "100%",
                      }}
                      alt=""
                      aria-hidden="true"
                      onLoad={event =>
                        (event.target.parentNode.style.opacity = 1)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <aside className="mt-8 font-crimson text-lightGrey normal-case text-2xl sm:text-3xl	tracking-normal max-w-xl">
              See why our online classes are perfect for you and your pup!
            </aside>
          </div>
        </div>
      </Container>
      {/* Testimonials */}
      <Container>
        <div className="text-center  pt-40 pb-20 ">
          <h2 className="">Hear from others who love our online classes...</h2>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center">
          {TESTIMONIALS.map((testi, index) => (
            <TestimonialCard data={testi} key={index} />
          ))}
        </div>
      </Container>

      {/* How it Works */}
      <Container noMax>
        <div
          className="absolute -top-[5%] w-full h-full"
          style={{ zIndex: 10 }}
        >
          <ServicesBlob />
        </div>
        <div
          className="flex flex-col items-center relative mt-24 pt-12 mb-12 max-w-xl mx-auto px-4"
          style={{ zIndex: 20 }}
        >
          <h2 className="tracking-wide font-semibold text-lightBlack mb-16">
            How The Class Works
          </h2>
          {how_it_works.map(how => {
            return renderHowItWorks(
              how.title_and_description.richText,
              how.emoji.richText[0].text
            )
          })}
          <ClassCardV2
            benefits={includes_list.richText.map(rt => rt.text)}
            classTimes={class_dates}
            duration={duration.text}
            enroll_link={enroll_link}
            price={price}
            title={title.text}
          />
          <div className="text-lightBlack mt-12 max-w-lg mx-auto px-4 xl:px-0 whitespace-pre-line">
            <h2 className="mb-4 font-semibold">Office hours</h2>
            <p>The first Tuesday of every month at 7 p.m., MST</p>
            <p className="mb-16">
              The second Saturday of every month at 1:30 p.m MST
            </p>
            <h2 className="mb-4 font-semibold">Refunds</h2>
            <p className="mb-16">
              Classes are non-refundable from the time of purchase. Please check
              your schedule carefully to ensure that you can commit before
              registering.
            </p>
          </div>
        </div>
      </Container>

      {/* Testimonials */}
      <Container>
        <div className="text-center  pt-20 pb-10 ">
          <h2 className="">What others are saying...</h2>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center">
          {TESTIMONIALS_GENERAL.map((testi, index) => (
            <TestimonialCard data={testi} key={index} />
          ))}
        </div>
      </Container>

      {faq && faq.length > 0 && (
        <Container noMax>
          <div
            className="flex flex-col relative mt-24 pt-12 mb-12 max-w-3xl mx-auto px-4"
            style={{ zIndex: 20 }}
          >
            <h2 className="mb-10 font-semibold text-center">
              Frequently Asked Questions
            </h2>
            {faq.map(faq => (
              <div key={faq.question.text} className="mb-12">
                <h3 className="mb-2 font-crimson text-lightBlack font-semibold  normal-case text-2xl	tracking-normal">
                  {faq.question.text}
                </h3>
                <PrismicRichText
                  field={faq.answer.richText}
                  components={{
                    paragraph: ({ children }) => <p className="">{children}</p>,
                  }}
                />
              </div>
            ))}
          </div>
        </Container>
      )}
      <Container noMax>
        <MasterClassEmailSignup />
      </Container>
    </Layout>
  )
}
export const Head = ({ data }) => {
  const {
    prismicClass: { data: pData },
  } = data
  return (
    <Seo title={pData.title.text} description={pData.description.text}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Course",
          name: pData.title.text,
          description: pData.description.text,
          provider: {
            "@type": "Organization",
            name: "Scott's School for Dogs",
            sameAs: "https://www.scottsschoolfordogs.com",
          },
        })}
      </script>
    </Seo>
  )
}

export const query = graphql`
  query ($id: String) {
    prismicClass(id: { eq: $id }) {
      _previewable
      last_publication_date
      url
      uid
      data {
        description {
          text
          richText
        }
        imagine_benefits {
          richText
          text
        }
        imagine_final_title {
          richText
          text
        }
        imagine_title {
          richText
          text
        }
        imagine_sub_title {
          text
        }
        title {
          richText
          text
        }
        enroll_link {
          slug
          url
          uid
          type
          target
          size
          raw
          id
          link_type
          tags
        }
        featured_image {
          gatsbyImageData
          alt
        }
        dog_will_learn {
          richText
        }
        you_will_learn {
          richText
        }
        includes_list {
          richText
        }
        price
        how_it_works {
          emoji {
            richText
          }
          title_and_description {
            richText
          }
        }
        class_dates {
          end_date
          start_date
          day_and_time {
            richText
            text
          }
          note {
            richText
            text
          }
        }
        duration {
          richText
          text
        }
        faq {
          answer {
            richText
          }
          question {
            text
          }
        }
      }
    }
  }
`

export default Classes
