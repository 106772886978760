import * as React from "react"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { HiChevronDown, HiArrowSmRight } from "react-icons/hi"
import { Link } from "gatsby"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function MenuDropdown({ label, allHref, items, items2, isNew }) {
  // items2 is the new class implementation
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-600",
              "realtive group rounded-md inline-flex items-center font-semibold hover:text-gray-900 focus:outline-none  tracking-wider text-md  mr-6 pb-1"
            )}
          >
            <span>{label}</span>
            <HiChevronDown
              className={classNames(
                open ? "text-gray-900" : "text-gray-600",
                "ml-0 h-5 w-5 group-hover:text-gray-900"
              )}
              aria-hidden="true"
            />
            {isNew && (
              <span className="absolute leading-4 -top-3 -left-3 -rotate-[15deg] transition	ease-in-out	 text-white font-medium text-[8px] bg-primary group-hover:rotate-[-2deg] px-1 py-1/4 rounded">
                NEW
              </span>
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-[1000] left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {items2 &&
                    items2.map(({ node }) => (
                      <Link
                        key={node.url}
                        to={`${node.url}`}
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <div className="">
                          <p className="text-base font-medium text-gray-900">
                            {node.data.title.text}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            {node.data.description.text}
                          </p>
                        </div>
                      </Link>
                    ))}
                  {items &&
                    items.map(
                      ({ node: { frontmatter } }) =>
                        frontmatter.is_active && (
                          <Link
                            key={frontmatter.name}
                            to={`${allHref}/${frontmatter.slug}/`}
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <div className="">
                              <p className="text-base font-medium text-gray-900">
                                {frontmatter.title}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {frontmatter.plain_label}
                              </p>
                            </div>
                          </Link>
                        )
                    )}
                </div>
                <Link
                  to={allHref + "/"}
                  className="-m-3 p-3 flex w-['100%'] items-center rounded-md text-base font-medium text-gray-900  transition ease-in-out duration-150"
                >
                  <div className="w-[100%]  px-5 py-5 bg-gray-50 hover:bg-gray-100 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                    <div className="flow-root">
                      <span className="flex items-center">
                        See All {label}
                        <HiArrowSmRight className="ml-2" />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
