import React from "react"
import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { Link } from "gatsby"
import Burger from "@animated-burgers/burger-squeeze"
import "@animated-burgers/burger-squeeze/dist/styles.css"

export default function MobileMenu({ items, services, classes }) {
  const renderItem = (label, to) => {
    return (
      <div key={label}>
        <Menu.Item>
          {({ active }) => (
            <Link
              to={to}
              className=""
              // style={{ color: "#585858" }}
              activeClassName="text-black border-b-2 border-black"
            >
              <div
                className={`${
                  active ? " text-white" : "text-gray-900"
                } group flex w-full items-center justify-center text-center rounded-md px-2 py-4  font-semibold tracking-wider text-md`}
              >
                {label}
              </div>
            </Link>
          )}
        </Menu.Item>
      </div>
    )
  }

  return (
    <div
      className="md:hidden relative w-56 text-right"
      style={{ zIndex: 2000 }}
    >
      <Menu as="div" className="relative inline-block text-left mr-4">
        <Menu.Button
          as="div"
          className="inline-flex w-full justify-center rounded-md bg-primary px-4 py-2 text-xs font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {({ open }) => <Burger isOpen={open} className="!text-[0.5rem]" />}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-[90vw] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {items.map(item => renderItem(item.label, item.to))}
            {/* TODO add services links */}
            {/* {services.map(({ node: { frontmatter } }) =>
              renderItem(frontmatter.title, `services/${frontmatter.slug}`)
            )} */}

            {renderItem("Private Training", "/services/")}
            {renderItem("Classes", "/classes/")}
            {renderItem("Contact", "/contact/")}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
