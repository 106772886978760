import React from "react"
import PropTypes from "prop-types"
import Button from "./button"
import { CheckMark } from "./icons"
import { PrismicLink } from "@prismicio/react"
import dayjs from "dayjs"

const ClassCardV2 = ({
  title,
  duration,
  price,
  benefits,
  classTimes,
  enroll_link,
}) => {
  const renderClassDates = classTime => {
    const { start_date, end_date, day_and_time, note } = classTime

    // If start_date has alread passed return nothing
    if (new Date(start_date) < new Date()) {
      return null
    }

    return (
      <li className=" mb-4" key={classTime.start_date}>
        <p className="flex-1">{`${dayjs(start_date).format("MMMM D")} - ${dayjs(
          end_date
        ).format("MMMM D")}, ${day_and_time.text}`}</p>
        <p className="italic">{note.text}</p>
      </li>
    )
  }

  return (
    <div className="my-14 flex justify-center">
      <div
        className="flex flex-col rounded-2xl bg-white p-6 md:p-12 max-w-xl text-lightBlack order-2 "
        style={{ filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15))" }}
      >
        <h4 className="font-crimson text-3xl font-semibold mb-1">{title}</h4>
        <span className="mb-6 uppercase font-thin rounded-full py-1 px-4 text-xs text-white bg-lightBlack w-fit">
          {duration}
        </span>
        <p className="w-fit text-xl font-medium mb-6">${price}</p>
        <p className="pb-1">Includes:</p>
        <ul className="mb-8">
          {benefits.map(benefit => (
            <li className="flex items-top pb-2  " key={benefit}>
              <div className="mt-1 w-7">
                <CheckMark />
              </div>
              <span className="flex-1">{benefit}</span>
            </li>
          ))}
        </ul>

        <h5 className="font-open font-bold text-base mb-2">
          Next Available Classes:
        </h5>
        <ul className="mb-8">
          {classTimes.map(classTime => renderClassDates(classTime))}
        </ul>
        {/* <PrismicLink field={enroll_link} className="mx-auto">
          <Button style={{ width: "fit-content", margin: "0px auto" }}>
            Enroll Now
          </Button>
        </PrismicLink> */}
        <a href={enroll_link.url} className="mx-auto">
          <Button style={{ width: "fit-content", margin: "0px auto" }}>
            Enroll Now
          </Button>
        </a>
      </div>
    </div>
  )
}

ClassCardV2.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  benefits: PropTypes.array.isRequired,
  classTimes: PropTypes.array.isRequired,
  enroll_link: PropTypes.object.isRequired,
}

export default ClassCardV2
