import React from "react"

const SquareYellowBlob = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 508 422"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M302.23 421.242C139.374 414.4 29.0837 376.841 3.15015 189.48C-22.7834 2.11999 148.008 2.12373 274.803 2.08581C401.599 2.04786 493.41 -24.8409 505.544 144.179C517.679 313.199 465.085 428.085 302.23 421.242Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default SquareYellowBlob
