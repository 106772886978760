import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  pathname,
  title,
  isBlogPost,
  datePublished,
  image,
  children,
}) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            email
            siteUrl
          }
        }
      }
    `
  )

  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    author,
    email,
  } = siteMetadata

  const seo = {
    title: title ? `${title}` : defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    author,
    email,
  }

  const baseSchema = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: seo.url,
      name: seo.title,
      description: seo.description,
    },
  ]

  const schema = isBlogPost
    ? [
        ...baseSchema,
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": seo.url,
                name: seo.title,
              },
            },
          ],
        },
        {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          url: seo.url,
          name: seo.title,
          headline: seo.title,
          image: {
            "@type": "ImageObject",
            url: seo.image,
          },
          author: {
            "@type": "Person",
            name: seo.author,
          },
          // publisher: {
          //   '@type': 'Organization',
          //   url: organization.url,
          //   logo: organization.logo,
          //   name: organization.name,
          // },
          // mainEntityOfPage: {
          //   '@type': 'WebSite',
          //   '@id': canonicalUrl,
          // },
          datePublished,
        },
      ]
    : baseSchema

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta
        name="keywords"
        content="Dog Training, Online Dog Classes, Private Dog Training, Reactive Dog Training"
      />
      <meta name="robots" content="index, follow" />
      {image && <meta name="image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      {image && <meta property="og:image" content={seo.image} />}
      <meta property="og:url" content={seo.url} />

      {image && <meta name="twitter:image" content={seo.image} />}
      {/* <meta name="twitter:creator" content={seo.twitterUsername} /> */}
      {/* <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"
      /> */}
      <script type="application/ld+json">{JSON.stringify({ schema })}</script>
      {children}
    </>
  )
}

export default Seo
