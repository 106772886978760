import React from "react"

const ServicesBlob = () => {
  return (
    <svg
      width="100%"
      height="110%"
      viewBox="0 0 1437 1942"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M0 0C0 0 347 73.9418 726 73.9418C1105 73.9418 1437 0 1437 0V1942C1437 1942 897.091 1913.92 551 1919.3C335.631 1922.65 0 1942 0 1942V0Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default ServicesBlob
