import React from "react"

const SmallYellowBlob = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 448 457"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M347.153 56.0293C481.526 131.315 477.15 357.382 358.842 427.165C258.459 486.374 -74.6274 455.001 49.8178 337.285C174.263 219.57 41.1671 272.053 3.84086 119.084C-33.4854 -33.8859 212.78 -19.256 347.153 56.0293Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default SmallYellowBlob
