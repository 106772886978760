import React from "react"

export const CheckMark = ({ width = 18, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 18 17`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M6.00232 11.4515L7.60293 8.60517L8.8034 6.97869L10.8042 4.94559L13.6053 2.50588L15.2059 1.28602L17.6068 0.0661621L14.8057 2.9125L11.6045 6.57207L8.40324 11.4515L6.00232 16.3309L3.20124 11.8581L0 7.79193L1.60062 8.19855L3.20124 9.01179L6.00232 11.4515Z"
        fill="#F5CF6B"
      />
    </svg>
  )
}
