import React from "react"

const DarkBlob = ({ color = "#393939" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1437 932"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M0 0C421 123 902.366 93.8835 1257 96.4977C1374.91 97.3669 1437 104.59 1437 104.59V872.323C1064.24 880.094 691.508 920.796 318 930.5C173.773 934.247 0 920.34 0 920.34V0Z"
        fill={color}
      />
    </svg>
  )
}

export default DarkBlob
