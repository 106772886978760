import * as React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Button from "./button"
import { StaticImage } from "gatsby-plugin-image"
import MobileMenu from "./mobile-menu"
import MenuDropdown from "./dropdown"
import { useMatch } from "@reach/router"

const menuItems = [
  { label: "Home", to: "/" },
  { label: "About", to: "/about/" },
  { label: "Tips", to: "/tips/" },
]
const VideoItems = [
  { label: "Video Course", to: "/courses/my-dog-is-reactive-now-what/" },
]

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      classes: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//classes//" }
          frontmatter: { is_class: { eq: true } }
        }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              is_active
              is_class
              plain_label
            }
          }
        }
      }
      classes2: allPrismicClass(sort: { fields: data___group }) {
        edges {
          node {
            url
            data {
              group
              title {
                text
              }
              description {
                text
              }
            }
          }
        }
      }
      services: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//classes//" }
          frontmatter: { is_class: { ne: true } }
        }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              is_active
              is_class
              plain_label
            }
          }
        }
      }
    }
  `)

  const isVideo = useMatch("/courses/*")

  return (
    <header
      className="bg-lightYellow flex flex-col w-full px-2 xl:px-0 "
      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", zIndex: "1000" }}
    >
      {/* <div className="flex justify-between items-center"> */}
      {!isVideo && (
        <div className=" bg-darkGrey py-2 px-2 -mx-2 xl:mx-0">
          <div className="max-w-6xl flex justify-end items-center  mx-auto text-xs lg:text-sm">
            <a
              className="text-lessLightGrey mr-4 md:mr-12 hover:text-white"
              href="tel:+12086448301"
            >
              (208) 644-8301
            </a>
            <a
              className="text-lessLightGrey hover:text-white"
              href="mailto: taylor@scottsschoolfordogs.com"
            >
              taylor@scottsschoolfordogs.com
            </a>
          </div>
        </div>
      )}
      <div className=" flex justify-between items-center flex-1 mx-auto max-w-6xl w-full">
        <Link
          to="/"
          style={{
            fontSize: `var(--font-sm)`,
            textDecoration: `none`,
          }}
          className="font-crimson text-xl"
        >
          <div className="py-1">
            <StaticImage
              src={"../images/round-transparent.png"}
              height={110}
              placeholder="tracedSVG"
              transformOptions={{ cropFocus: "center" }}
              alt="Scott's school for dogs logo"
            />
          </div>
        </Link>

        {/* Desktop Menu */}
        <div className="hidden md:flex md:items-center">
          {!isVideo &&
            menuItems.map(item => (
              <Link
                key={item.label}
                to={item.to}
                className="text-gray-600 hover:text-black font-semibold tracking-wider text-md  mr-8 pb-1"
                // style={{ color: "#585858" }}
                activeClassName="text-black border-b-2 border-black"
              >
                {item.label}
              </Link>
            ))}
          {VideoItems.map((item, i) => (
            <div key={`${item.to} ${i}`} className="relative group">
              <Link
                key={item.label}
                to={item.to}
                className=" text-gray-600 group-hover:text-black font-semibold tracking-wider text-md  mr-8 pb-1"
                // style={{ color: "#585858" }}
                activeClassName="text-black border-b-2 border-black"
              >
                {item.label}
              </Link>
            </div>
          ))}
          {!isVideo && (
            <>
              <MenuDropdown
                label="Private Training"
                allHref={"/services"}
                items={data.services.edges}
              />

              <MenuDropdown
                label="Classes"
                allHref={"/classes"}
                // items={data.classes.edges}
                items2={data.classes2.edges}
                isNew
              />

              <Link to="/classes/">
                <Button>Enroll Now</Button>
              </Link>
            </>
          )}
          {isVideo && (
            // <Link to="/contact/">
            <Button
              onClick={() =>
                navigate(
                  `https://scotts-school-for-dogs.disco.co/p/my-dog-is-reactive-now-what/register`
                )
              }
            >
              Buy Now
            </Button>
            // </Link>
          )}
        </div>
        {/* Mobile Menu */}
        <MobileMenu
          items={[...menuItems, ...VideoItems]}
          services={data.services.edges}
          classes={data.classes.edges}
        />
      </div>
      {/* </div> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
