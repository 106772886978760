import { Link } from "gatsby"
import React from "react"
import { FiInstagram } from "react-icons/fi"
import SmartLink from "./smart-link"

const Footer = () => {
  return (
    <footer className="bg-darkGrey text-lessLightGrey md:flex justify-around lg:px-32 py-16">
      <div className="flex flex-col items-center sm:items-left mb-6">
        <Link to="/" className="pb-3 text-lessLightGrey ">
          Home
        </Link>
        <Link to="/about/" className="pb-3 text-lessLightGrey ">
          About
        </Link>
        <Link to="/tips/" className="pb-3 text-lessLightGrey ">
          Tips
        </Link>
        <Link
          to="/courses/my-dog-is-reactive-now-what/"
          className="pb-3 text-lessLightGrey "
        >
          Video Course
        </Link>
        <Link to="/services/" className="pb-3 text-lessLightGrey ">
          Services
        </Link>
        <Link to="/classes/" className="pb-3 text-lessLightGrey ">
          Classes
        </Link>
        <Link to="/contact/" className="pb-3 text-lessLightGrey ">
          Contact
        </Link>
      </div>
      <div className="flex flex-col items-center md:items-start">
        <h2>Contact Us</h2>
        <a className="text-lessLightGrey mb-2" href="tel:+12086448301">
          (208) 644-8301
        </a>
        <a
          className="text-lessLightGrey mb-2"
          href="mailto: taylor@scottsschoolfordogs.com"
        >
          taylor@scottsschoolfordogs.com
        </a>
        <SmartLink href={"https://www.instagram.com/scottsschoolfordogs/"}>
          <span className="text-lessLightGrey mb-6 hover:underline flex items-center">
            <FiInstagram className="inline mr-1" />
            Instagram
          </span>
        </SmartLink>{" "}
        <p className="text-lessLightGrey mb-2">Boise, ID 83706</p>
        <p className="text-lessLightGrey text-center md:text-left">
          © {new Date().getFullYear()} &middot; Scott&apos;s Dog Training, LLC{" "}
        </p>
      </div>
    </footer>
  )
}

export default Footer
