import React from "react"

const Container = ({ children, noMax = false, style }) => {
  return (
    <section
      className={`mx-auto ${noMax ? "" : "max-w-6xl"} relative`}
      style={{ ...style }}
    >
      {children}
    </section>
  )
}

export default Container
