import React from "react"

const SquareYellowBlobTwo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 556 509"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M324.693 1.10649C146.299 12.9962 26.2675 60.7784 2.28728 287.443C-21.693 514.108 165.565 510.293 304.587 507.511C443.608 504.728 544.91 535.126 554.197 330.901C563.483 126.676 503.087 -10.7832 324.693 1.10649Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default SquareYellowBlobTwo
